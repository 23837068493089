<template>
    <span v-if="entry.hasBody">
        <b-button ref="viewDetails_Button" v-b-modal="entry.id" class="mr-2" variant="info">
            View Details
        </b-button>
        <b-modal
                ref="viewDetails_Modal"
                @shown="shown"
                @hide="hide"
                v-if="entry.hasBody"
                scrollable
                :id="entry.id"
                ok-only
                size="xl"
                title-tag="h1">
            <template v-slot:modal-title>
                <span v-html="entry.title"></span>
            </template>
            <section :aria-label="entry.title">
                <div class="flex-center" v-if="!parsedBody"><progress-spinner/></div>
                <div ref="parsedBody" class="my-4, entry-details"></div>
                <downloadable-file-list v-if="parsedBody" :entry="entry"></downloadable-file-list>
            </section>
        </b-modal>
    </span>
</template>

<script>

    import DownloadableFileList from "./DownloadableFileList"
    import { fetchAttachment } from "@/services/attachment_download.service";
    import http from "@/services/http.service";
    import { monitorSproutVideo } from 'videoanalytics'
    import sendVideoSessionsToServer from '@/services/synchronize_video_analytics.service'
    import MceAccordion from "./MceAccordion"

    export default {
        name: 'view-details',
        // eslint-disable-next-line
        components: { DownloadableFileList, mceAccordion: MceAccordion },
        props: {
            entry: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                visible: false,
                parsedBody: null,
                loadedImages: false,
                videoMonitors: []
            }
        },
        methods: {

            async getParsedBody() {

                // urls for inline images are of the form .../rafile/i/123456/v/1/f/0/filename.ext
                // the file index for the file in the current version is the number after "/f/"
                // this regex matches the url pattern and gets the file index in the first match group
                const inlineImageRegex = /\/rafile\/i\/(\d+)\/v\/(\d+)\/f\/(\d+)\//

                const getAttachmentIndex = img => {

                    const match = img.src.match(inlineImageRegex);

                    if (match) {
                        return parseInt(match[1]) === this.entry.identifier && parseInt(match[2]) === this.entry.version
                            ? match[3]
                            : -1;
                    } else {
                        return NaN;
                    }

                }

                const setupAttachmentImages = async element => {

                    const responseToDataUrl = ({data, headers}) => {
                      const binary = new Uint8Array(data).reduce((s, b) => s + String.fromCharCode(b), '');
                      return `data:${headers['content-type']};base64,${btoa(binary)}`;
                    }

                    const setupGoodImage = (img, index) => {

                        img.removeAttribute("src");

                        return fetchAttachment(this.entry, parseInt(index), 'arraybuffer')
                            .then(r => img.src = responseToDataUrl(r))
                            .catch(e => {
                                console.error("Error loading image:", e.message);
                                setupBadImage(img, "Error loading image");
                            });

                    }

                    const setupBadImage = (img, message="Image unavailable") => {

                        const span = document.createElement("span");
                        span.innerText = message;
                        span.setAttribute("class", "alert alert-warning");
                        img.parentElement.replaceChild(span, img);

                        return Promise.resolve();

                    }

                    const setupAttachmentImage = ([img, index]) =>
                        index >= 0 ? setupGoodImage(img, index) : setupBadImage(img);

                    const promises = [...element.getElementsByTagName("img")]
                        .map(img => [img, getAttachmentIndex(img)])
                        .filter(([, index]) => !isNaN(index))
                        .map(setupAttachmentImage);

                    return Promise.all(promises);

                }

                const setupMathJax = async element => {

                    if (element.querySelector(".wbs-eq-markup")) {

                        await this.$mathJax(element);

                    }

                }

                const loadBody = async () => {

                    try {
                        return (await http.get(`/api/feed/${this.entry.id}/body`)).data.body
                    } catch {
                        return "<span class='alert alert-danger'>Error loading content</span>"
                    }

                }

                const fixRelativeAnchors = anchors =>{
                  const myWbsHost = `https://${process.env.VUE_APP_MYWBS_HOST}`;
                  let relativeAnchors = anchors.filter(ele => ele.href.startsWith(location.href));
                  relativeAnchors.forEach(ele => ele.href = `${myWbsHost}${ele.href.substring(location.href.length - 1, ele.href.length)}` );
                }

                if (this.entry.hasBody) {
                    const element = document.createElement("div");
                    element.innerHTML = await loadBody();
                    await setupAttachmentImages(element);
                    await setupMathJax(element);
                    fixRelativeAnchors(Array.from(element.querySelectorAll('a')));
                    return element.innerHTML;
                }

            },

            async shown() {

                const setupVideoMonitoring = async () => {

                    const sproutVideoIframes = [...this.$refs.parsedBody.getElementsByTagName("iframe")]
                        .filter(f => f.getAttribute("class") === "sproutvideo-player");

                    this.videoMonitors = await Promise.all([
                        ...sproutVideoIframes.map(f => monitorSproutVideo(f, this.entry.id))
                    ]);

                }

                const setupMceAccordions = accordions => {
                    const mceAccordionComponent = this.$options.components["mceAccordion"];
                    const mceAccordionConstructor = this.$root.constructor.extend(mceAccordionComponent);

                    accordions.forEach(accordion => {
                        const summary = accordion.querySelector("summary");
                        const bodyElements = accordion.querySelectorAll("summary ~ *");
                        const bodyWrapperDiv = document.createElement('div');

                        accordion.appendChild(bodyWrapperDiv);
                        bodyElements.forEach(node => bodyWrapperDiv.appendChild(node));

                        new mceAccordionConstructor({
                            propsData: { summary: summary.innerHTML, body: bodyWrapperDiv.innerHTML }
                        }).$mount(accordion);
                    });
                }

                this.parsedBody = this.parsedBody ?? await this.getParsedBody();

                setTimeout(() => {
                    this.$refs.parsedBody.innerHTML = this.parsedBody;
                    setupVideoMonitoring();
                    setupMceAccordions(Array.from(this.$refs.parsedBody.querySelectorAll('details.mce-accordion')));
                });

            },

            async hide(event) {

                // return if there are no video monitors (prevents infinite recursion)
                if (!this.videoMonitors.length) return;

                event.preventDefault();

                await Promise.all(this.videoMonitors.map(m => m.destroy()));
                this.videoMonitors = [];

                // now actually hide the modal
                this.$bvModal.hide(this.entry.id);

                await sendVideoSessionsToServer();

            }

        }

    }

</script>

<style scoped>
    >>> .entry-details img {
        max-width: 100%;
        height: auto;
    }
    >>> .entry-details a {
        text-decoration: underline;
    }
    .flex-center {
        display: flex;
        justify-content: center;
    }
</style>